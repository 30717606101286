@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=PT+Serif&display=swap"); */

* {
  font-family: "Tajawal", sans-serif;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-track
{
	border: 3px solid #060606;
	background-color: #b2bec3;
}

::-webkit-scrollbar
{
	width: 7px;
	background-color: transparent;
}

::-webkit-scrollbar-thumb
{
	background-color: #424242;
	border-radius: 10px;
}
html {
  direction: rtl;
}
