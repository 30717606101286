.card {
  background: rgba(50, 39, 23, 0.169);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.1px);
  -webkit-backdrop-filter: blur(2.1px);
  border: 1px solid rgba(238, 183, 107, 0.17);
}

.cards::-webkit-scrollbar{
  display: none;
}

.expanded {
  transition: all 1s ease-in-out;
}